<template>
  <!-- 租车订单 -->
  <div ref="CarRentalOrder" class="CarRentalOrder">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="订单编号：">
          <el-input v-model="form.id" clearable placeholder="请输入订单编号" />
        </el-form-item>
        <el-form-item label="下单人手机">
          <el-input
            v-model="form.ordererPhone"
            clearable
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item label="驾驶员">
          <el-input
            v-model="form.rentalDriverName"
            clearable
            placeholder="请输入姓名"
          />
        </el-form-item>
        <el-form-item label="驾驶员手机：">
          <el-input
            v-model="form.rentalDriverPhone"
            clearable
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item label="订单状态：">
          <el-select
            v-model="form.orderStatus"
            multiple
            clearable
            placeholder="请选择订单状态"
          >
            <el-option
              v-for="role in orderListStatus"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间起：">
          <el-date-picker
            v-model="form.createTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="下单时间止：">
          <el-date-picker
            v-model="form.createTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间起：">
          <el-date-picker
            v-model="form.paymentStartDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间止：">
          <el-date-picker
            v-model="form.paymentEndDate"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="取车时间起：">
          <el-date-picker
            v-model="form.getVehicleTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="取车时间止：">
          <el-date-picker
            v-model="form.getVehicleTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="还车时间起：">
          <el-date-picker
            v-model="form.returnVehicleTimeStart"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="还车时间止：">
          <el-date-picker
            v-model="form.returnVehicleTimeEnd"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="租车车型：">
          <el-input
            v-model="form.rentalModelName"
            clearable
            placeholder="请输入车型名称"
          />
        </el-form-item>
        <el-form-item label="车型分类：">
          <el-select
            v-model="form.charteredVehicleModelTypeIds"
            multiple
            clearable
            placeholder="请选择车型分类"
          >
            <el-option
              v-for="role in carTypeList"
              :key="role.id"
              :label="role.vehicleTypeName"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车商名称：">
          <el-select
            v-model="form.carServiceProviderId"
            clearable
            placeholder="请选择车商"
          >
            <el-option
              v-for="role in carDealerList"
              :key="role.id"
              :label="role.name"
              :value="role.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryBtn"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportBtn()"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="tableHeight"
    ></Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  searchRentTableAPI,
  providerListAPI,
  renderCarTypeListAPI,
  exportRentOrderAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      total: 0,
      form: {
        pageSize: 10,
        currentPage: 1,
        id: "",
        ordererPhone: "",
        rentalDriverName: "",
        rentalDriverPhone: "",
        getVehicleTimeStart: "",
        getVehicleTimeEnd: "",
        returnVehicleTimeStart: "",
        returnVehicleTimeEnd: "",
        rentalModelName: "",
        carServiceProviderId: "",
        charteredVehicleModelTypeIds: [],
        orderStatus: [],
        createTimeStart: "",
        createTimeEnd: "",
        paymentStartDate: null,
        paymentEndDate: null,
      },
      tableHeight: 0,
      titleName: [
        {
          title: "订单编号",
          props: "id",
        },
        {
          title: "下单人",
          props: "ordererPhone",
          width: 130,
        },
        {
          title: "驾驶员姓名",
          props: "rentalDriverName",
          width: 100,
        },
        {
          title: "驾驶员身份证号码",
          props: "idCardNo",
          width: 180,
          SpecialJudgment: (res) => {
            return this.decryptDes(res).replace(
              /^(.{4})(?:\d+)(.{3})$/,
              "$1******$2"
            );
          },
        },
        {
          title: "驾驶员手机",
          props: "rentalDriverPhone",
          width: 130,
        },
        {
          title: "取车城市",
          props: "cityName",
        },
        {
          title: "取车点",
          props: "pickLocationName",
          width: 130,
        },
        {
          title: "取车时间",
          props: "getVehicleTime",
          width: 170,
        },
        {
          title: "还车城市",
          props: "returnLocationCityName",
        },
        {
          title: "还车点",
          props: "returnLocationName",
          width: 130,
        },
        {
          title: "还车时间",
          props: "returnVehicleTime",
          width: 170,
        },
        {
          title: "租车天数",
          props: "days",
        },
        {
          title: "租车车型",
          props: "rentalModelName",
        },
        {
          title: "车型分类",
          props: "charteredVehicleModelTypeName",
        },
        {
          title: "车牌号",
          props: "vehiclePlateNumber",
        },
        {
          title: "实际还车时间",
          props: "returnSchedulingTime",
          width: 170,
        },
        {
          title: "超时时长",
          props: "timeoutDuration",
          width: 150,
          SpecialJudgment: (res) => {
            let hour, minute;
            hour = Math.floor(res / 60);
            minute = res - hour * 60;
            return res
              ? (hour ? hour + "小时" : "") + (minute ? minute + "分" : "")
              : "";
          },
        },
        {
          title: "用车里程数(KM)",
          props: "useDistance",
          width: 150,
        },
        {
          title: "车商名称",
          props: "carServiceProviderName",
        },
        {
          title: "下单时间",
          props: "createTime",
          width: 170,
        },
        {
          title: "支付时间",
          props: "wxTransactionTime",
          width: 170,
        },
        {
          title: "订单状态",
          props: "orderStatus",
          width: 100,
          SpecialJudgment: (res) => {
            let text = "";
            switch (res) {
              case 10:
                text = "待支付";
                break;
              case 20:
                text = "预订成功";
                break;
              case 30:
                text = "已取车";
                break;
              case 40:
                text = "已完成";
                break;
              case 50:
                text = "用户已退订";
                break;
              case 60:
                text = "后台已退订";
                break;
              case 70:
                text = "订单取消";
                break;
            }
            return text;
          },
        },
        {
          title: "订单金额",
          props: "totalPrices",
        },
        {
          title: "退订金额",
          props: "refundOrderPrices",
        },
        {
          title: "退订手续费",
          props: "refundOrderServicePrices",
          width: 100,
        },
        {
          title: "退订时间",
          props: "refundAt",
          width: 170,
        },
        {
          title: "缴租车押金",
          props: "vehicleDeposit",
        },
        {
          title: "退租车押金",
          props: "vehicleDepositRefund",
        },
        {
          title: "租车押金扣除金额",
          props: "vehicleDepositDeduct",
        },
        {
          title: "租车押金扣除原因",
          props: "vehicleDepositDeductRemark",
        },
        {
          title: "退租车押金时间",
          props: "vehicleDepositRefundTime",
        },
        {
          title: "缴违章押金",
          props: "violationDeposit",
        },
        {
          title: "退违章押金",
          props: "violationDepositRefund",
        },
        {
          title: "违章押金扣除金额",
          props: "violationDepositDeduct",
        },
        {
          title: "违章押金扣除原因",
          props: "violationDepositDeductRemark",
        },
        {
          title: "退违章押金时间",
          props: "violationDepositRefundTime",
        },
      ],
      tableData: [],
      orderListStatus: [
        {
          value: 10,
          label: "待支付",
        },
        {
          value: 20,
          label: "预订成功",
        },
        {
          value: 30,
          label: "已取车",
        },
        {
          value: 40,
          label: "已完成",
        },
        {
          value: 50,
          label: "用户已退订",
        },
        {
          value: 60,
          label: "后台已退订",
        },
        {
          value: 70,
          label: "订单取消",
        },
      ],
      carTypeList: [],
      carDealerList: [],
    };
  },
  methods: {
    decryptDes(message, key = "inheritech_key") {
      var cryptoJs = require("crypto-js");
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    renderData() {
      searchRentTableAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    queryBtn() {
      this.form.currentPage = 1;
      this.renderData();
    },
    exportBtn() {
      exportRentOrderAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "租车订单 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    computeHeight() {
      const wholeHeight = this.$refs.CarRentalOrder.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        36;
    },
    // 获取上一个月时间,返回yyyy-MM-dd字符串
    getLastMonthTime(date) {
      //  1    2    3    4    5    6    7    8    9   10    11   12月
      var daysInMonth = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      var strYear = date.getFullYear();
      var strDay = date.getDate();
      var strMonth = date.getMonth() + 1;
      //一、解决闰年平年的二月份天数   //平年28天、闰年29天//能被4整除且不能被100整除的为闰年,或能被100整除且能被400整除
      if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
        daysInMonth[2] = 29;
      }
      if (strMonth - 1 === 0) {
        //二、解决跨年问题
        strYear -= 1;
        strMonth = 12;
      } else {
        strMonth -= 1;
      }
      //  strDay = daysInMonth[strMonth] >= strDay ? strDay : daysInMonth[strMonth];
      strDay = Math.min(strDay, daysInMonth[strMonth]); //三、前一个月日期不一定和今天同一号，例如3.31的前一个月日期是2.28；9.30前一个月日期是8.30

      if (strMonth < 10) {
        //给个位数的月、日补零
        strMonth = "0" + strMonth;
      }
      if (strDay < 10) {
        strDay = "0" + strDay;
      }
      var datastr = strYear + "-" + strMonth + "-" + strDay;
      return datastr;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    renderCarTypeListAPI({ businessType: 5 }).then((res) => {
      if (res.code == "SUCCESS") {
        this.carTypeList = res.data;
      }
    });
    providerListAPI({ openBusiness: 5 }).then((res) => {
      this.carDealerList = res.data;
    });
    // 时间计算，默认一个月
    this.form.createTimeStart = this.getLastMonthTime(new Date()) + " 00:00:00";
    let day = new Date();
    this.form.createTimeEnd =
      day.getFullYear() +
      "-" +
      (day.getMonth() + 1) +
      "-" +
      day.getDate() +
      " 23:59:59";
    this.queryBtn();
  },
};
</script>

<style scoped lang="scss">
.CarRentalOrder {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.Table {
  margin-top: 16px;
}
</style>
